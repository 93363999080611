// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import entries, { type WhatsNewEntry } from '../data/whats-new-entries';
import getDateString from '../utils/date';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateHeading = styled.h2`
  margin-top: 1.66667rem;
`;

export default class WhatsNew extends React.Component<{}> {
  render() {
    return (
      <Container>
        {entries.map((entry: WhatsNewEntry, index: number) => (
          <Entry key={index} entries={entry.entries} date={entry.date} />
        ))}
      </Container>
    );
  }
}

class Entry extends React.Component<{
  date: Array<number>,
  entries: Node,
}> {
  render() {
    const { date, entries } = this.props;

    return (
      <>
        <DateHeading>{getDateString(date)}</DateHeading>
        <ul>{entries}</ul>
      </>
    );
  }
}
