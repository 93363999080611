// @flow
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { border } from '../utils/styles';

const GhostButton = styled(GatsbyLink)`
  color: unset;
  ${border};
  padding: 0.33333rem 0.5rem;
  width: auto;
  transition: background-color 0.3s ease-in;

  &:hover,
  &:active {
    color: unset;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export default GhostButton;
