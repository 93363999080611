// @flow
import React, { type Node } from 'react';
import Link from '../components/link';
import route from '../routes';
import G from '../components/glossary-link';

export type WhatsNewEntry = {
  date: Array<number>,
  entries: Node,
};

const whatsNewEntries: Array<WhatsNewEntry> = [
  {
    date: [2021, 12, 12],
    entries: (
      <li>
        Added <Link to={route.blog_2021_08_08_change_creek}>Change Creek</Link>{' '}
        blog post
      </li>
    ),
  },
  {
    date: [2021, 9, 1],
    entries: (
      <li>
        Added <Link to={route.blog_2021_08_07_hall_creek}>Hall Creek</Link> blog
        post
      </li>
    ),
  },
  {
    date: [2021, 8, 29],
    entries: (
      <li>
        Added{' '}
        <Link to={route.blog_2021_08_06_south_fork_snoqualmie}>
          South Fork Snoqualmie
        </Link>{' '}
        blog post
      </li>
    ),
  },
  {
    date: [2021, 7, 24],
    entries: (
      <>
        <li>
          Merged the terms on the abbreviation page into the glossary since it
          was kind of redundant. Consequently there is no more abbreviation page
          and there are loads of new glossary terms
        </li>
        <li>
          New terms are <G>ABC</G>, <G>ACA</G>, <G>ACE</G>, <G>ART</G>,{' '}
          <G>Association for Canyoneering Education</G>,{' '}
          <G>best person at risk</G>, <G>BFR</G>, <G>BG</G>,{' '}
          <G>big friendly rock</G>, <G>BLM</G>, <G>bolt-kit</G>, <G>BPAR</G>,{' '}
          <G>Bureau of Land Management</G>, <G>CAC</G>, <G>canyoner</G>,{' '}
          <G>canyoning</G>, <G>CARE</G>, <G>CCC</G>, <G>CFS</G>,{' '}
          <G>Civilian Conservation Corps</G>, <G>CST</G>, <G>CUSA</G>, <G>DS</G>
          , <G>Emergency Medical Services</G>, <G>EMS</G>, <G>GCNRA</G>,{' '}
          <G>GORP</G>, <G>GSENM</G>, <G>HCV</G>, <G>HITRR</G>, <G>ICOpro</G>,{' '}
          <G>LCV</G>, <G>LEO</G>, <G>LMAR</G>, <G>LMR</G>, <G>LNT</G>,{' '}
          <G>LPAR</G>, <G>MA</G>, <G>MIA exit</G>, <G>MMO</G>,{' '}
          <G>National Park Service</G>, <G>NPS</G>, <G>ORV</G>, <G>OW</G>,{' '}
          <G>PAS</G>, <G>PCD</G>, <G>PMP</G>, <G>PNW</G>, <G>SAR</G>,{' '}
          <G>SITLA</G>, <G>SRENE</G>, <G>SUWA</G>, <G>TG</G>, <G>TNG</G>,{' '}
          <G>USGS</G>, <G>V7 Academy</G>, <G>VRG</G>, <G>WCCM</G>, <G>YDS</G>,{' '}
          <G>ZAC</G>, and <G>ZNP</G>
        </li>
      </>
    ),
  },
  {
    date: [2021, 7, 10],
    entries: (
      <>
        <li>
          Added{' '}
          <Link to={route.page_single_solid_natural_anchors}>
            Single Solid Natural Anchors
          </Link>{' '}
          article (Located in How to Canyoneer -> Anchors)
        </li>
        <li>
          Added <G>cinch wrap</G>, <G>frost knot</G>, <G>water knot</G>, and{' '}
          <G>shelf</G> glossary terms
        </li>
      </>
    ),
  },
  {
    date: [2021, 7, 6],
    entries: (
      <li>
        Added <Link to={route.blog_2021_07_05_adams_canyon}>Adams Canyon</Link>{' '}
        blog post
      </li>
    ),
  },
  {
    date: [2021, 7, 5],
    entries: (
      <li>
        Added <Link to={route.page_dear}>DEAR</Link> article. DEAR is an acronym
        for qualities of an ideal anchor location.
      </li>
    ),
  },
  {
    date: [2021, 7, 4],
    entries: (
      <>
        <li>
          Started <Link to={route.page_anchors}>Anchors</Link> article
        </li>
        <li>
          Added <Link to={route.page_earnest}>EARNEST</Link> article. EARNEST is
          an acronym for qualities of an ideal anchor.
        </li>
      </>
    ),
  },
  {
    date: [2021, 3, 28],
    entries: (
      <li>
        Added{' '}
        <Link to={route.blog_2020_09_27_great_white_icicle}>
          Great White Icicle
        </Link>{' '}
        blog post
      </li>
    ),
  },
  {
    date: [2021, 3, 12],
    entries: (
      <li>
        Added 2020 mistakes to{' '}
        <Link to={route.blog_2019_06_30_every_mistake + '#2020'}>
          Every Mistake I've Ever Made Canyoneering
        </Link>{' '}
        blog post.
      </li>
    ),
  },
  {
    date: [2020, 9, 7],
    entries: (
      <li>
        Added{' '}
        <Link to={route.blog_2020_09_07_super_amazing_ropewiki_map}>
          Super Amazing Ropewiki Map
        </Link>{' '}
        blog post
      </li>
    ),
  },
  {
    date: [2020, 7, 26],
    entries: (
      <>
        <li>
          Added{' '}
          <Link to={route.blog_2020_07_12_lincolns_first_canyon}>
            Lincoln's First Canyon
          </Link>{' '}
          blog post
        </li>
        <li>
          Added{' '}
          <Link to={route.blog_2020_07_24_crumbling_canyon}>
            Crumbling Canyon
          </Link>{' '}
          blog post
        </li>
      </>
    ),
  },
  {
    date: [2020, 4, 30],
    entries: (
      <>
        <li>
          Added{' '}
          <Link to={route.aca_cl2_skills_checklist}>
            Canyon Leader 2 Skills Checklist
          </Link>
        </li>
        <li>
          Updated{' '}
          <Link to={route.blog_2020_04_26_aca_skills_checklist_updates}>
            2020 Skills Checklists Updates
          </Link>{' '}
          blog post to include CL2 changes
        </li>
        <li>
          Added <G>Courtesy Start</G> and <G>Cut and Lower</G> glossary terms
        </li>
      </>
    ),
  },
  {
    date: [2020, 4, 29],
    entries: (
      <>
        <li>
          Added{' '}
          <Link to={route.aca_cl1_skills_checklist}>
            Canyon Leader 1 Skills Checklist
          </Link>
        </li>
        <li>
          Updated{' '}
          <Link to={route.blog_2020_04_26_aca_skills_checklist_updates}>
            2020 Skills Checklists Updates
          </Link>{' '}
          blog post to include CL1 changes
        </li>
        <li>
          Added <G>Buddy Check</G>, <G>Courtesy Loop</G>, <G>Redundant Wrap</G>,{' '}
          <G>Rope Entanglement</G>, <G>Safety Check</G>, <G>Safety Line</G>, and{' '}
          <G>Traverse Line</G> glossary terms. Maybe more?
        </li>
      </>
    ),
  },
  {
    date: [2020, 4, 28],
    entries: (
      <>
        <li>
          Added <Link to={route.aca_core_skills_checklist}>Core</Link> and{' '}
          <Link to={route.aca_aspirant_skills_checklist}>Aspirant</Link> Skills
          Checklists
        </li>
        <li>
          Added{' '}
          <Link to={route.aca_2018_skills_checklist}>
            2018 Skills Checklists
          </Link>{' '}
          because why not
        </li>
        <li>
          Added half-finished blog post with{' '}
          <Link to={route.blog_2020_04_26_aca_skills_checklist_updates}>
            differences between the 2018 and 2020 ACA Skills Checklists
          </Link>
        </li>
        <li>
          Added <G>Floating Disconnect</G>, <G>Smear</G>, <G>Water Hazard</G>,
          and <G>Static Courtesy Rigging</G> glossary terms
        </li>
      </>
    ),
  },
  {
    date: [2020, 3, 12],
    entries: (
      <li>
        Added 2019 mistakes to{' '}
        <Link to={route.blog_2019_06_30_every_mistake + '#2019'}>
          Every Mistake I've Ever Made Canyoneering
        </Link>{' '}
        blog post.
      </li>
    ),
  },
  {
    date: [2020, 2, 23],
    entries: (
      <li>
        <Link to={route.blog_2020_02_23_the_2020_utah_canyoneers_shirt}>
          Rappel Devices of the 2020 Utah Canyoneers Shirt
        </Link>{' '}
        blog post
      </li>
    ),
  },
  {
    date: [2019, 11, 10],
    entries: (
      <li>
        <Link to={route.page_quizzes}>Quizzes</Link> page - all the full-length
        quizzes in one place.
      </li>
    ),
  },
  {
    date: [2019, 11, 6],
    entries: (
      <li>
        <Link to={`${route.page_canyon_rating_system}#canyon-rating-quiz`}>
          Canyon rating quiz
        </Link>
        .
      </li>
    ),
  },
  {
    date: [2019, 9, 21],
    entries: (
      <>
        <li>
          <Link to={route.page_rope}>Rope</Link> page
        </li>
        <li>
          I made a{' '}
          <Link to={`${route.page_rope}#rope-quiz`}>rope quiz thing</Link>. Give
          a try at the bottom of the rope page.
        </li>
      </>
    ),
  },
  {
    date: [2019, 6, 30],
    entries: (
      <li>
        <Link to={route.blog_2019_06_30_every_mistake}>
          Every mistake I've ever made canyoneering
        </Link>{' '}
        blog post
      </li>
    ),
  },
  {
    date: [2019, 6, 25],
    entries: (
      <li>
        <Link to={route.blog_2019_06_25_worrier}>The Worrier</Link> blog post
      </li>
    ),
  },
  {
    date: [2019, 6, 17],
    entries: (
      <li>
        <Link to={route.blog_2019_06_15_rocky_mouth}>
          Rocky Mouth in Hard Mode
        </Link>{' '}
        blog post
      </li>
    ),
  },
  {
    date: [2019, 6, 6],
    entries: (
      <>
        <li>
          <Link to={route.blog_2019_05_18_rappel_maui}>Rappel Maui</Link> blog
          post
        </li>
        <li>
          <Link to={route.page_water_bottle}>Water bottle</Link> page
        </li>
      </>
    ),
  },
  {
    date: [2019, 4, 3],
    entries: (
      <li>
        <Link to={route.blog_2019_03_16_north_wash}>North Wash March 2019</Link>{' '}
        blog post.
      </li>
    ),
  },
  {
    date: [2019, 3, 22],
    entries: (
      <>
        <li>
          Glossary is done! And by done I mean I have added all the words that I
          had on my list except for a couple of dumb ones. And I haven't added
          any new words to the list in hours. HOURS! The definitions are all
          probably wrong, though.
        </li>
        <li>
          <G>Bench</G>, <G>Bluff</G>, <G>Butte</G>, <G>Deviation</G>,{' '}
          <G>Equalette</G>, <G>Foot Entrapment</G>, <G>Packraft</G>,{' '}
          <G>Pig Rig</G>, <G>Piggyback</G>, <G>Quad</G>,{' '}
          <G>Recirculating Current</G>, <G>Saddle</G>, <G>Siphon</G>,{' '}
          <G>Strainer</G>, <G>Swift Water</G>, <G>Undercut</G>, and{' '}
          <G>Via Ferrata</G> glossary terms.
        </li>
      </>
    ),
  },
  {
    date: [2019, 3, 21],
    entries: (
      <li>
        <G>Chute</G>, <G>Cross Joint</G>, <G>Cryptobiotic Soil</G>, <G>Draw</G>,{' '}
        <G>Epic</G>, <G>Hallway</G>, <G>Joint</G>, <G>Mountain</G>,{' '}
        <G>Nose (Landform)</G>, <G>Peak</G>, <G>Ralston Rock</G>,{' '}
        <G>Ralstone</G>, <G>Ridge</G>, <G>Side Drainage</G>, <G>Slide</G>,{' '}
        <G>Spur</G> glossary terms.
      </li>
    ),
  },
  {
    date: [2019, 3, 13],
    entries: (
      <>
        <li>
          Tons of little changes and fixes like:
          <ul>
            <li>
              <Link to={route.page_pronunciation}>Pronunciation Guide</Link> now
              has self-links for its terms. To link to any pronunciation or
              glossary term just long-tap or right-click the term and click
              "Copy Link Address" to copy it to the clipboard.
            </li>
            <li>
              Links to any glossary term, you know, terms like <G>Zions</G>, now
              have a faint underline to indicate that hovering on them with a
              mouse will pop up the definition.
            </li>
            <li>
              YouTube videos like the one on the{' '}
              <Link to={route.blog_2018_03_03_sandthrax}>
                Sandthrax blog post
              </Link>{' '}
              now fit the width of your mobile screen.
            </li>
          </ul>
        </li>
        <li>
          <G>Contour line</G>, <G>free rappel</G>, <G>scree</G>, <G>talus</G>,{' '}
          <G>belay station</G>, <G>focal point</G>, <G>cheater stick</G>,{' '}
          <G>happy hooker</G>, <G>cordura</G>, <G>crux</G>, <G>social trail</G>,{' '}
          <G>bag up rigging system</G>, <G>bag down rigging system</G>,{' '}
          <G>tail up rigging system</G>, and <G>tail down rigging system</G>{' '}
          glossary terms. I only have like 30 more terms on my list!
        </li>
      </>
    ),
  },
  {
    date: [2019, 2, 23],
    entries: (
      <li>
        Add <Link to={route.page_pull}>The Pull</Link> page
      </li>
    ),
  },
  {
    date: [2019, 2, 1],
    entries: (
      <>
        <li>
          Add{' '}
          <Link to={route.page_step}>
            S.T.E.P. - A Checklist for the Last Rappeller
          </Link>{' '}
          page.
        </li>
        <li>
          New icon! Inspired by the curves of a slot canyon and forming a "CC"
          for "Canyon Companion". The curve is slightly asymmetric which
          indicates the imperfections that are prevalent in my blog technical
          information.
        </li>
      </>
    ),
  },
  {
    date: [2019, 1, 14],
    entries: (
      <li>
        500 <Link to={route.page_glossary}>glossary</Link> terms!
      </li>
    ),
  },
  {
    date: [2019, 1, 13],
    entries: (
      <li>
        Consolidate a few redundant glossary terms. Add new terms:{' '}
        <ul>
          <li>
            <G>Chest Pouch</G>, <G>Chester</G>, <G>PicPocket</G>, <G>Bogley</G>,{' '}
            <G>Canyon skid plate</G>, <G>CanyonWerks</G>,{' '}
            <G>Multi-directional load</G>, <G>Triaxial load</G>, and{' '}
            <G>Cross load</G>.
          </li>
          <li>
            Carabiner parts: <G>Gate</G>, <G>Nose (Carabiner)</G>, <G>Basket</G>
            , <G id="working-end-carabiner">Working end</G>, <G>Wide end</G>,{' '}
            <G>Spine</G>, <G>Skinny end</G>, <G>Runner end</G>,{' '}
            <G>Major axis</G>, and <G>Minor axis</G>.
          </li>
          <li>
            Knots: <G id="figure-8-knot">Figure 8</G>,{' '}
            <G id="figure-of-8-knot">Figure of 8</G>, <G>Figure 8 on a bight</G>
            , <G>Flemish bend</G>, and <G>Rethreaded figure 8 bend</G>.
          </li>
          <li>As of today there are 496 terms and 416 definitions.</li>
        </ul>
      </li>
    ),
  },
  {
    date: [2019, 1, 3],
    entries: (
      <li>
        <Link to={route.page_carabiner}>Carabiner</Link> article.
      </li>
    ),
  },
  {
    date: [2018, 12, 5],
    entries: (
      <>
        <li>
          <Link to={route.page_partner_capture}>Partner Capture</Link> page.
        </li>
      </>
    ),
  },
  {
    date: [2018, 11, 18],
    entries: (
      <>
        <li>
          <Link to={route.blog_2018_11_11_lomatium}>Lomatium</Link> blog post.
        </li>
      </>
    ),
  },
  {
    date: [2018, 11, 17],
    entries: (
      <>
        <li>
          <Link to={route.blog_2018_10_26_spencer}>Spencer</Link>,{' '}
          <Link to={route.blog_2018_11_09_moonflower_u_turn}>
            Moonflower/U-Turn
          </Link>
          , and{' '}
          <Link to={route.blog_2018_11_10_tierdrop_not_tierdrop}>
            Tierdrop/Not Tierdrop
          </Link>{' '}
          blog posts.
        </li>
      </>
    ),
  },
  {
    date: [2018, 10, 23],
    entries: (
      <>
        <li>
          <Link to={route.page_other_canyon_rating_systems}>
            Other Canyon Rating Systems
          </Link>{' '}
          page.
        </li>
      </>
    ),
  },
  {
    date: [2018, 10, 21],
    entries: (
      <>
        <li>
          Launch Canyon Companion website. The old domain now redirects here.
        </li>
        <li>
          <Link to={route.blog_2018_09_20_mystery_revisited}>Mystery</Link>,{' '}
          <Link to={route.blog_2018_09_21_engelstead}>Engelstead</Link>, and{' '}
          <Link to={route.blog_2018_09_22_rock_canyon}>Rock Canyon</Link> blog
          posts.
        </li>
        <li>
          Add links to all the pictures on most of the blog posts. The link is
          at the bottom of the page if it's there. The newer the blog post, the
          more likely it will have lots of extra pictures.
        </li>
        <li>
          New Canyon Rating Widget on the{' '}
          <Link to={route.page_canyon_rating_system}>Canyon Rating System</Link>{' '}
          page and most of the blog post pages.
        </li>
      </>
    ),
  },
  {
    date: [2018, 9, 29],
    entries: (
      <>
        <li>
          Another site redesign! I seriously spend more time designing this
          website than I do creating content for it.
        </li>
        <li>
          <Link to={route.blog_2018_09_10_subway}>Subway</Link> blog post.
        </li>
        <li>
          A bunch of new <Link to={route.page_glossary}>glossary terms</Link>.
        </li>
      </>
    ),
  },
  {
    date: [2018, 5, 26],
    entries: (
      <>
        <li>
          <Link to={route.blog_2018_04_19_halls_creek}>Hall's Creek</Link> blog
          post.
        </li>
      </>
    ),
  },
  {
    date: [2018, 5, 22],
    entries: (
      <>
        <li>
          <Link to={route.blog_2018_05_19_robbers_roost}>
            Robber's Roost May 2018
          </Link>{' '}
          blog post.
        </li>
      </>
    ),
  },
  {
    date: [2018, 4, 16],
    entries: (
      <>
        <li>
          Love letter to my{' '}
          <Link to={route.blog_2018_04_15_one_hundred_canyons_later}>
            dearly departed harness
          </Link>
        </li>
        <li>
          Added{' '}
          <Link to={route.page_check}>
            What to C.H.E.C.K. before rappelling
          </Link>{' '}
          page.
        </li>
        <li>
          terms: <G>elongation</G>, <G>gear loop</G>, <G>leg loop</G>,{' '}
          <G>rope spool</G>, <G>sheath slippage</G>, <G>short rope</G>,{' '}
          <G>tensile strength</G>, <G>waist belt</G>, and <G>z rig</G>.
        </li>
      </>
    ),
  },
  {
    date: [2018, 4, 15],
    entries: (
      <>
        <li>
          Fleshed out{' '}
          <Link to={route.page_canyon_rating_system}>Canyon Rating System</Link>{' '}
          page. It's got a little widget on it now.
        </li>
        <li>
          Glossary terms: <G>tape</G>.
        </li>
      </>
    ),
  },
  {
    date: [2018, 4, 2],
    entries: (
      <>
        <li>
          <Link to={route.blog_2018_03_24_gla_rendezvous}>
            ACA Guide/Leader/Aspirant Rendezvous
          </Link>{' '}
          blog post.
        </li>
      </>
    ),
  },
  {
    date: [2018, 4, 1],
    entries: (
      <>
        <li>Complete rewrite of the entire site!</li>
        <li>
          <Link to={route.page_glossary}>Glossary</Link> the most comprehensive
          for Colorado Plateau Canyoneering
        </li>
        <li>
          <Link to={route.page_pronunciation}>Pronunciation Guide</Link>
        </li>
      </>
    ),
  },
];

export default whatsNewEntries;
