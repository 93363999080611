// @flow
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import type { FrontMatter, ImageType } from '../utils/types';
import frontMatterMap from '../data/front-matter';
import WhatsNew from '../components/whats-new';
import { small_screen } from '../utils/styles';
import { Image, Layout, withFrontMatter } from '../components';
import UnstyledGhostButton from '../components/ghost-button';

const LinkBar = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.83333rem;
  margin-top: 0.25rem;

  ${small_screen} {
    flex-direction: column;
    align-items: center;
  }
`;

const GhostButton = styled(UnstyledGhostButton)`
  flex-grow: 1;
  width: 100%;
  text-align: center;
  margin-bottom: 0.83333rem;
  margin-left: 0.66667rem;

  ${small_screen} {
    margin-left: 0;
  }
`;

const GhostButtonFirstChild = styled(GhostButton)`
  margin-left: 0;
`;

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
  },
};

class Root extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;
    const canyoneering = frontMatterMap.canyoneering;
    const blog = frontMatterMap.blog;
    const about = frontMatterMap.about;

    return (
      <Layout frontMatter={frontMatter} hideHeader hideFooter>
        <h1>Canyon Companion</h1>
        <p>{frontMatter.preview}</p>
        <LinkBar>
          <GhostButtonFirstChild to={canyoneering.route}>
            {canyoneering.title}
          </GhostButtonFirstChild>
          <GhostButton to={blog.route}>{blog.title}</GhostButton>
          <GhostButton to={about.route}>{about.title}</GhostButton>
        </LinkBar>
        <Image image={data._1} caption="The defenseless swimming position" />
        <hr />
        <WhatsNew />
      </Layout>
    );
  }
}

export default withFrontMatter('root')(Root);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2021-08-06-south-fork-snoqualmie/P8060264.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
